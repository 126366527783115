import { defineComponent, reactive, toRefs, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getPart, getTemplate } from "@/http/home";
import { imgUrl } from "@/utils/urlManage";
export default defineComponent({
  setup(props) {
    const route = useRoute();
    const router = useRouter();

    const _this = reactive({
      frameUrl: ""
    });

    const methods = {
      async getData() {
        const res = await getPart({
          code: route.query.code,
          orgCode: sessionStorage.getItem("orgCode") || ""
        });
        if (!res.data.success) return; // 获取模板详情

        const {
          data
        } = await getTemplate({
          code: res.data.data.templateCode,
          orgCode: sessionStorage.getItem("orgCode") || ""
        });
        if (!data.success) return;
        _this.frameUrl = imgUrl(data.data.fileUrl);
      }

    };
    onMounted(() => {
      methods.getData();
    });
    return { ...toRefs(_this),
      ...methods
    };
  }

});